/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong",
    img: "img"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/useful-knowledge/otitis-externa/'], ['en', 'https://www.hear.com/useful-knowledge/otitis-externa/'], ['en-US', 'https://www.hear.com/useful-knowledge/otitis-externa/'], ['en-CA', 'https://ca.hear.com/useful-knowledge/otitis-externa/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "causes-symptoms-and-therapy",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#causes-symptoms-and-therapy",
    "aria-label": "causes symptoms and therapy permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Causes, symptoms, and therapy"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "An inflammation of the ear canal (also otitis externa) is defined as a bacterial, fungal, or allergic inflammation of the skin and subcutis in the outer ear canal"), ". In some cases, an inflammation of the ear canal can also involve the outer ear or the tympanic membrane (eardrum). The latter is known as myringitis. It is a medical health issue and should be dealt with in the correct manner to ensure there is no further damage."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "causes-of-an-ear-canal-inflammation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#causes-of-an-ear-canal-inflammation",
    "aria-label": "causes of an ear canal inflammation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Causes of an ear canal inflammation"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A trigger for an inflammation of the ear canal (otitis externa) is microtrauma. This term describes minor injuries to the skin, caused by, for instance, rigorous cleaning of the ear using a cotton swab, damaging the dermis of the ear canal.\nAnother factor for the inflammation of the ear canal can be skin sensitization (an allergic reaction) as caused by certain shampoos and other artificial agents like hairsprays and soaps. Additionally, increased ossification (exostoses of the ear canal) or a chronic middle ear infection can be at the root of the condition. Diseases resulting in immunodeficiency – for example, neurodermatitis, psoriasis, and diabetes mellitus – can, by way of a general skin inflammation (dermatitis), also lead to an inflammation of the ear canal."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/otitis-externa-representation.png",
    alt: "Otitis externa representationt",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "symptoms-of-otitis-externa",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#symptoms-of-otitis-externa",
    "aria-label": "symptoms of otitis externa permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Symptoms of otitis externa"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "An inflammation of the ear canal at first may feel like a sensation of itching, followed by extreme earache, especially when chewing or tucking at the outer ear. Likewise, the transmission of sound in the ear can be impaired, causing a perception of sounds that is muffled and unclear. Frequently, otitis externa is accompanied with swelling or obstruction of the ear canal due to an accumulation of mucus. For this form of ear canal inflammation, the distinction can be made between the oozing type (secretion) and the dry variant (skin flakes). In rare cases, the adjacent bone tissue can be affected as well, which can lead to a breakdown of the surrounding cranial nerves at the base of the skull."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-is-an-ear-canal-inflammation-diagnosed",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-is-an-ear-canal-inflammation-diagnosed",
    "aria-label": "how is an ear canal inflammation diagnosed permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How is an ear canal inflammation diagnosed?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Generally, the first step is an examination of the ear canal to determine if there is an obvious abscess or boil present. Moreover, by pulling back the outer ear, the presence of pain should be assessed. In any case, an ENT-specialist should be consulted for a diagnosis. If necessary, a cleaning of the ear canal (ear micro-scopy), a smear, and/or hearing test should be performed."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "therapy-for-an-inflammation-of-the-ear-canal",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#therapy-for-an-inflammation-of-the-ear-canal",
    "aria-label": "therapy for an inflammation of the ear canal permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Therapy for an inflammation of the ear canal"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The intended treatment outcome of an inflammation of the ear canal is the decline in swelling and the restoration of impaired hearing – respectively, the reduction of sustained hearing impairment. Initially, ", React.createElement(_components.strong, null, "the ear canal should be cleaned"), ". In the case of a conclusive result, the hearing impairment should be treated with medicated salves or creams. In severe cases, it might be necessary to prescribe a round of antibiotics. Most of the time, however, otitis externa is treated conservatively and, in certain cases, surgically."), "\n", React.createElement(ButtonCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
